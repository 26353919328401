import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login";
import { UserInfo } from "@firebase/auth-types";

import AppContent from "./AppContent";
import SettingsContent from "./SettingsContent";

export const Router: React.FC<{
  user?: UserInfo;
  bar: JSX.Element;
  drawer: JSX.Element;
}> = props => {
  return (
    <BrowserRouter>
      {props.bar}
      <Switch>
        <Route path="/" exact>
          {props.user ? <Redirect to="/app" /> : <Login />}
        </Route>

        <Route path="/app">
          {props.user ? (
            <div>
              <AppContent />
              {props.drawer}
            </div>
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        <Route path="/settings">
          {props.user ? (
            <>
              <SettingsContent />
              {props.drawer}
            </>
          ) : (
            <Redirect to="/" />
          )}
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
