import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { UserInfo } from "@firebase/auth-types";

import { auth } from "./firebase";
import Router from "./components/Router";
import Bar from "./components/Bar";
import MenuDrawer from "./components/MenuDrawer";
import { login, loaded, getStravaAthleteAndClubs, toggleMenu } from "./store/appSlice";
import { RootState } from "./store/rootReducer";

import "typeface-roboto";
import "./App.css";

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, user, menuOpen } = useSelector((state: RootState) => state.app);

  const toggleDrawer = () => {
    dispatch(toggleMenu())
  };

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        const upayload: UserInfo = {
          displayName: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          photoURL: user.photoURL,
          providerId: user.providerId,
          uid: user.uid
        };
        await dispatch(login({ user: upayload }));
        dispatch(getStravaAthleteAndClubs());
      }
      dispatch(loaded());
    });
  }, [dispatch]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Router
      user={user}
      bar={<Bar onAvatarClick={toggleDrawer} />}
      drawer={<MenuDrawer open={menuOpen} onBackdropClick={toggleDrawer} />}
    ></Router>
  );
};

export default App;
