import React from "react";
import { useSelector } from "react-redux";

import { Container, Grid, Fab, Typography } from "@material-ui/core";
import { RootState } from "../store/rootReducer";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AddOutlined } from "@material-ui/icons";

import WorkoutCard from "./WorkoutCard";
import WorkoutForm from "./WorkoutForm";
import { Workout } from "../sidekickTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    }
  })
);

export const AppContent: React.FC<{}> = props => {
  const classes = useStyles();

  const [workoutDialogOpen, setWorkoutDialogOpen] = React.useState(false);
  const [workout, setWorkout] = React.useState<Workout | null>(null);

  const handleNewWorkoutOpen = () => {
    setWorkout(null);
    setWorkoutDialogOpen(true);
  };

  const handleNewWorkoutClose = (value: Workout | null) => {
    setWorkoutDialogOpen(false);
    // dispatch value to save workout
  };

  const { workouts } = useSelector((state: RootState) => state.workouts);
  const orderedWorkouts = Object.values(workouts).sort((a, b) => {
    if (a.epochDate < b.epochDate) {
      return -1;
    }
    if (a.epochDate === b.epochDate) {
      return 0;
    }
    return 1;
  });
  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        {orderedWorkouts.length ? (
          orderedWorkouts.map(w => (
            <Grid item key={w.id} xs={12} sm={6} md={4} lg={3}>
              <WorkoutCard workout={w} id={w.id} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography align="center">
              Everyone is lazy right now...plan a workout!
            </Typography>
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <Paper square>Today, {getDayBucket(0).toDateString()}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper square>Tomorrow, {getDayBucket(1).toDateString()}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper square>{getDayBucket(2).toDateString()}</Paper>
        </Grid> */}
      </Grid>
      <WorkoutForm
        workout={workout}
        open={workoutDialogOpen}
        onClose={handleNewWorkoutClose}
      />
      <Fab
        onClick={handleNewWorkoutOpen}
        className={classes.fab}
        color="primary"
        aria-label="add"
      >
        <AddOutlined />
      </Fab>
    </Container>
  );
};

export default AppContent;
