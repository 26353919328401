import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

function signIn() {
  window.open("/popup.html", "name", "height=600,width=400");
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      margin: theme.spacing(3)
    },
    subtitle: {
      margin: theme.spacing(2)
    },
    login: {
      margin: theme.spacing(4),
      textAlign: "center",
    },
  })
);

export const Login: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container direction="column" justify="center">
      <Grid item>
        <Typography align="center" variant="h2" className={classes.title}>
          Sidekick Fitness
        </Typography>
        <Typography align="center" variant="h5" className={classes.subtitle}>
          Sync up for a workout.<br/>Do it with ease.
        </Typography>
        <Typography className={classes.login} >
          <img
            alt=""
            src="/img/btn_strava_connectwith_orange.svg"
            onClick={signIn}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
