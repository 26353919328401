import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "@firebase/auth-types";
import { DocumentData } from "@firebase/firestore-types";
import { WorkoutMessage, Workout } from "../sidekickTypes";
import { AppThunk } from "../store";
import firebase, { firestore, generateId } from "../firebase";

export interface WorkoutDisplay {
  id: string;
  workout: Workout;
}
interface CreateWorkoutPayload {
  workout: Workout;
}

type WorkoutDetailPayload = {} & WorkoutDisplay;

type WorkoutDisplayState = {
  workouts: { [key: string]: Workout };
  error: string;
  workoutDetailOpen: boolean;
  workoutDetail?: WorkoutDisplay;
};

const start = new Date();
const daysArray = [1, 2, 3, 4, 5, 6, 7].map((_, i) => {
  var day = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  day.setDate(start.getDate() + i);
  return day.getTime();
});
let days: { [key: number]: WorkoutDisplay[] } = {};
daysArray.forEach(d => (days[d] = []));
let initialState: WorkoutDisplayState = {
  workouts: {},
  error: "",
  workoutDetailOpen: false
};
const workoutDisplaySlice = createSlice({
  name: "workoutDisplay",
  initialState,
  reducers: {
    saveWorkoutSuccess(state, action: PayloadAction<CreateWorkoutPayload>) {
      const workout = action.payload.workout;
      // const bucket = state.workouts[workout.epochDate];
      state.workouts[workout.id] = workout;
    },
    workoutUpdateSuccess(state, action: PayloadAction<Workout>) {
      const workout = action.payload;
      state.workouts[workout.id] = workout;
    },
    workoutUpdateFailure(state, action: PayloadAction<Workout>) {
      // undo
      const workout = action.payload;
      state.workouts[workout.id] = workout;
    },
    fetchWorkoutsSuccess(
      state,
      action: PayloadAction<{ [key: string]: Workout }>
    ) {
      state.workouts = action.payload;
      // daysArray.forEach(day => {
      //   let bucket = action.payload[day];
      //   if (bucket && bucket.length) {
      //     state.workouts[day] = bucket;
      //   } else {
      //     state.workouts[day] = [];
      //   }
      // });
    },
    actionFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    openWorkoutDetail(state, action: PayloadAction<WorkoutDetailPayload>) {
      state.workoutDetail = action.payload;
      state.workoutDetailOpen = true;
    },
    closeWorkoutDetail(state) {
      state.workoutDetailOpen = false;
      state.workoutDetail = undefined;
    }
  }
});

export const {
  workoutUpdateSuccess,
  workoutUpdateFailure,
  fetchWorkoutsSuccess,
  saveWorkoutSuccess,
  actionFailed,
  openWorkoutDetail,
  closeWorkoutDetail
} = workoutDisplaySlice.actions;
export default workoutDisplaySlice.reducer;

// Thunks
export const fetchWorkouts = (): AppThunk<void> => async (dispatch, getState) => {
  try {
    const appState = getState().app;
    if (!appState.loggedIn) {
      console.log("Not authenticated");
      dispatch(actionFailed("Not Authenticated"));
      return;
    }
    const user = appState.user;
    if (!user) {
      dispatch(actionFailed("No User Account"));
      return;
    }

    const clubId = getState().app.athlete?.currentClubId;
    if (!clubId) {
      console.log("No club set!", getState().app.athlete);
      dispatch(actionFailed("No club set!"));
      return;
    }
    const now = new Date().getTime();
    await firestore
      .collection("clubs")
      .doc(`club:${clubId}`)
      .collection("workouts")
      .where("epochDate", ">=", now)
      .get()
      .then(workoutSnapshot => {
        let wkts: { [key: string]: Workout } = {};
        workoutSnapshot.forEach(wkt => {
          wkts[wkt.id] = wkt.data() as Workout;
        });
        // const workouts = workoutSnapshot.docs.map(d => {
        //   let id = d.id;
        //   let blob = d.data();
        //   return { id, workout: { ...blob } } as WorkoutDisplay;
        // });
        // const grouped = workouts.reduce((storage, item) => {
        //   var group = item.workout.epochDate;
        //   storage[group] = storage[group] || [];
        //   storage[group].push(item as WorkoutDisplay);
        //   return storage;
        // }, {} as { [key: number]: WorkoutDisplay[] });
        dispatch(fetchWorkoutsSuccess(wkts));
      })
      .catch(err => {
        console.error(err);
      });
  } catch (err) {
    dispatch(actionFailed(err.toString()));
  }
};

export const createWorkout = (wo: Workout): AppThunk<void> => async (
  dispatch,
  getState
) => {
  const workout = wo;

  const clubId = getState().app.athlete?.currentClubId;
  if (!clubId) {
    dispatch(actionFailed("No club set!"));
    return;
  }
  try {
    if (!workout.id) {
      workout.id = generateId();
    }
    await firestore
      .collection("clubs")
      .doc(`club:${clubId}`)
      .collection("workouts")
      .doc(workout.id)
      .set(workout, { merge: true })
      .then(_ => {
        dispatch(saveWorkoutSuccess({ workout }));
      })
      .catch(err => {
        console.log(err);
      });
  } catch (err) {
    dispatch(actionFailed(err.toString()));
  }
};

const alterJoiners = (
  fv: (...elements: any[]) => firebase.firestore.FieldValue
) => {
  return (workout: Workout): AppThunk<void> => async (dispatch, getState) => {
    const user = getState().app.user;
    if (!user) {
      dispatch(actionFailed("not authenticated"));
      return;
    }

    if (!workout.id) {
      dispatch(actionFailed("Unknown workout id"));
      return;
    }

    const clubId = getState().app.athlete?.currentClubId;
    if (!clubId) {
      dispatch(actionFailed("No club set!"));
      return;
    }

    const docRef = firestore
      .collection("clubs")
      .doc(`club:${clubId}`)
      .collection("workouts")
      .doc(workout.id);
    const unsub = docRef.onSnapshot(snap => {
      const w = snap.data() as Workout;
      dispatch(workoutUpdateSuccess(w));
    });
    await docRef
      .update({
        joiners: fv(user)
      })
      .then(_ => {})
      .catch(err => {
        console.log(err);
        dispatch(workoutUpdateFailure(workout));
      })
      .finally(() => {
        unsub();
      });
  };
};
export const leaveWorkout = alterJoiners(
  firebase.firestore.FieldValue.arrayRemove
);
export const joinWorkout = alterJoiners(
  firebase.firestore.FieldValue.arrayUnion
);

export const chatMessage = (
  workout: Workout,
  message: WorkoutMessage
): AppThunk<void> => async (dispatch, getState) => {
  if (!workout.id) {
    dispatch(actionFailed("Unknown workout id"));
    return;
  }

  const clubId = getState().app.athlete?.currentClubId;
  if (!clubId) {
    dispatch(actionFailed("No club set!"));
    return;
  }

  const docRef = firestore
    .collection("clubs")
    .doc(`club:${clubId}`)
    .collection("workouts")
    .doc(workout.id);
  const unsub = docRef.onSnapshot(snap => {
    const w = snap.data() as Workout;
    dispatch(workoutUpdateSuccess(w));
  });

  await docRef
    .update({
      messages: firebase.firestore.FieldValue.arrayUnion(message)
    })
    .then(_ => {})
    .catch(err => {
      console.log(err);
      dispatch(workoutUpdateFailure(workout));
    })
    .finally(() => {
      unsub();
    });
};
