import { UserInfo } from "@firebase/auth-types";

export type WorkoutTimeOfDay = "AM" | "PM";
export const allTimesOfDay: WorkoutTimeOfDay[] = ["AM", "PM"];
export type WorkoutType = "Ride" | "Run" | "Walk";
export const allWorkoutTypes: WorkoutType[] = ["Ride", "Run", "Walk"];

export type WorkoutMessage = {
  uId: string;
  epochDate: number;
  name: string;
  photo: string;
  text: string;
};

export type Workout = {
  id: string;
  epochDate: number;
  owner: UserInfo | undefined;
  type: WorkoutType;
  timeOfDay?: "AM" | "PM";
  description?: string;
  joiners?: UserInfo[];
  messages?: WorkoutMessage[];
};

export type DeviceToken = {
  userId: string;
  token: string;
};
