import { combineReducers } from "redux";
import appReducer from "./appSlice";
import workoutDisplayReducer from './workoutSlice';
import clubReducer from './clubSlice';

const rootReducer = combineReducers({
  app: appReducer,
  workouts: workoutDisplayReducer,
  clubs: clubReducer,
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;
