import React, { useEffect } from "react";
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Notifications } from "@material-ui/icons";
import { messaging } from "../firebase";
import { useThunkDispatch } from "../store";
import {
  saveNotificationToken,
  removeNotificationToken,
  getNotificationToken,
  performAction,
  actionComplete
} from "../store/appSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    }
  })
);

export const SettingsContent: React.FC<{}> = () => {
  const dispatch = useThunkDispatch();
  const [checked, setChecked] = React.useState<string[]>([]);
  const [notificationsEnabled, setNotificationsEnabled] = React.useState(false);

  useEffect(() => {
    if (messaging) {
      dispatch(performAction());
      messaging
        .getToken()
        .then(currentToken => {
          if (currentToken) {
            dispatch(getNotificationToken(currentToken))
              .then(dt => {
                setNotificationsEnabled(!!dt);
              })
              .finally(() => {
                dispatch(actionComplete());
              });
          } else {
            dispatch(actionComplete());
          }
        })
        .catch(err => {
          console.error(err);
          dispatch(actionComplete());
        });
    }
  }, [dispatch]);
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleNotifications = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (messaging) {
      // Get Instance ID token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      if (checked) {
        messaging.getToken().then(currentToken => {
          if (currentToken) {
            dispatch(saveNotificationToken(currentToken));
            setNotificationsEnabled(true);
          } else {
            // show permission request
            Notification.requestPermission().then(permission => {
              if (permission === "granted") {
                messaging &&
                  messaging.getToken().then(initialToken => {
                    dispatch(saveNotificationToken(initialToken));
                    setNotificationsEnabled(true);
                  });
              }
            });
          }
        });
      } else {
        messaging.getToken().then(currentToken => {
          if (currentToken) {
            dispatch(removeNotificationToken(currentToken));
            setNotificationsEnabled(false);
          }
        });
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <List>
        {messaging && (
          <ListItem>
            <ListItemIcon>
              <Notifications />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={handleNotifications}
                checked={notificationsEnabled}
              />
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </Container>
  );
};

export default SettingsContent;
