import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "@firebase/auth-types";
import { functions, firestore, auth } from "../firebase";
import { AppThunk } from ".";
import { getClubsFromStrava } from "../store/clubSlice";
import { fetchWorkouts } from "../store/workoutSlice";
import { DetailedAthlete, SummaryClub } from "../../functions/src/stravaTypes";
import { DeviceToken } from "../sidekickTypes";

interface AppAuth {
  loggedIn: boolean;
  user?: UserInfo;
  athlete: DetailedAthlete | null;
}

interface AppFilter {
  filter: "All" | "Just Me" | "Group";
  groupId: number | null;
}

interface LoginPayload {
  user: UserInfo;
}

interface FilterPayload {
  filter: "All" | "Just Me" | "Group";
  groupId?: number;
}

type AppState = {
  loading: boolean;
  performingAction: boolean;
  menuOpen: boolean;
} & AppAuth &
  AppFilter;

let initialState: AppState = {
  loading: true,
  performingAction: false,
  menuOpen: false,
  loggedIn: false,
  athlete: null,
  filter: "All",
  groupId: null
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loaded(state) {
      state.loading = false;
    },
    performAction(state) {
      state.performingAction = true;
    },
    actionComplete(state) {
      state.performingAction = false;
    },
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
    closeMenu(state) {
      state.menuOpen = false;
    },
    login(state, action: PayloadAction<LoginPayload>) {
      state.loggedIn = true;
      state.user = action.payload.user;
    },
    logout(state) {
      auth.signOut();
      state.loggedIn = false;
      state.user = undefined;
      state.athlete = null;
    },
    setFilter(state, action: PayloadAction<FilterPayload>) {
      const { filter, groupId = null } = action.payload;
      state.filter = filter;
      state.groupId = groupId;
    },
    onAthleteLoadSuccess(state, action: PayloadAction<DetailedAthlete>) {
      state.athlete = action.payload;
    },
    onSetCurrentClubSuccess(state, action: PayloadAction<SummaryClub>) {
      state.athlete!.currentClubId = action.payload.id;
    }
  }
});

export const {
  login,
  logout,
  loaded,
  onAthleteLoadSuccess,
  onSetCurrentClubSuccess,
  toggleMenu,
  closeMenu,
  performAction,
  actionComplete
} = appSlice.actions;

export default appSlice.reducer;

async function saveAthlete(athlete: DetailedAthlete, user: UserInfo) {
  let coll = firestore.collection("athletes");
  return coll.doc(`${user.uid}`).set(athlete, { merge: true });
}

async function getAthlete(user: UserInfo) {
  return firestore
    .collection("athletes")
    .doc(`${user.uid}`)
    .get()
    .catch(err => {
      console.log("ERR", err);
    });
}

export const getStravaAthleteAndClubs = (): AppThunk<void> => async (
  dispatch,
  getState
) => {
  const user = getState().app.user!;
  const getStravaAthlete = functions.httpsCallable("getStravaAthlete");
  try {
    dispatch(performAction());
    let athleteRef = await getAthlete(user)!;
    let athlete = athleteRef ? (athleteRef?.data() as DetailedAthlete) : null;
    if (!athlete) {
      athlete = (await getStravaAthlete()).data as DetailedAthlete;
      const clubs = await getClubsFromStrava();
      // only store a couple items
      athlete.clubs = clubs.map(club => {
        return {
          id: club.id,
          name: club.name,
          profile_medium: club.profile_medium
        } as SummaryClub;
      });
      console.log("Saving new athlete!", athlete.id);
      await saveAthlete(athlete, user);
    }
    dispatch(onAthleteLoadSuccess(athlete));

    if (athlete.currentClubId) {
      await dispatch(fetchWorkouts());
    }
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(actionComplete());
  }
};

export async function saveCurrentClub(club: SummaryClub, user: UserInfo) {
  return await firestore
    .collection("athletes")
    .doc(`${user.uid}`)
    .set(
      {
        currentClubId: club.id
      },
      { merge: true }
    );
}

export const setCurrentClub = (club: SummaryClub): AppThunk<void> => async (
  dispatch,
  getState
) => {
  const user = getState().app.user!;
  await saveCurrentClub(club, user);
  await dispatch(onSetCurrentClubSuccess(club));
  await dispatch(fetchWorkouts());
  await dispatch(closeMenu());
};

export const saveNotificationToken = (pushToken: string): AppThunk<void> => async (
  dispatch,
  getState
) => {
  if (!pushToken) {
    return;
  }
  const user = getState().app.user!;
  const device: DeviceToken = {
    userId: user.uid,
    token: pushToken
  };
  const devicesRef = firestore.collection("devices");
  return await devicesRef.doc(pushToken).set(device);
};

export const removeNotificationToken = (pushToken: string): AppThunk<void> => async (
  dispatch,
  getState
) => {
  if (!pushToken){
    return Promise.reject();
  }

   await firestore.collection("devices").doc(pushToken).delete();
  return Promise.resolve();
}

export const getNotificationToken = (pushToken: string): AppThunk<DeviceToken> => async (
  dispatch,
  getState
) => {
  if (!pushToken) {
    return Promise.reject();
  }
  const devicesRef = firestore.collection("devices");
  const ref = await devicesRef.doc(pushToken).get();
  return Promise.resolve(ref.data() as DeviceToken);
};
