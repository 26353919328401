import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";

firebase.initializeApp({
  apiKey: "AIzaSyA_tRqxh_g4SZDRHYjHXjYIRYTffeO1QdI",
  authDomain: "sidekick.fitness",
  databaseURL: "https://sidekick-fitness.firebaseio.com",
  projectId: "sidekick-fitness",
  storageBucket: "sidekick-fitness.appspot.com",
  messagingSenderId: "928179758677",
  appId: "1:928179758677:web:c56dd776e03bed4cf5d6dc",
  measurementId: "G-TKRRF1MTP3"
});

const functions = firebase.functions();
if (process.env.NODE_ENV !== "production") {
  functions.useFunctionsEmulator("http://localhost:5001");
}

export default firebase;
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const messagingSupported = firebase.messaging.isSupported();
let messaging: firebase.messaging.Messaging | null = null;
if (messagingSupported) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BFP9-83iFQYyHisy6zFN_HDB1_QgAE9-wzaor58YqpCdhaDERUdTZN8LENhJZ49EABy_nby1TWbQN4IykzQ6m9k"
  );
}
export { messaging };

// https://github.com/firebase/firebase-js-sdk/blob/master/packages/firestore/src/util/misc.ts
export const generateId = () => {
  // Alphanumeric characters
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  // assert(autoId.length === 20, 'Invalid auto ID: ' + autoId);
  return autoId;
};
export { functions };
