import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  Divider,
  Box,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  IconButton
} from "@material-ui/core";
import MenuOpen from "@material-ui/icons/MenuOpen";
import ExitToApp from "@material-ui/icons/ExitToApp";
import ViewAgendaOutlined from "@material-ui/icons/ViewAgendaOutlined";
import SettingsOutlined from "@material-ui/icons/SettingsOutlined";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { RootState } from "../store/rootReducer";
import { setCurrentClub, logout } from "../store/appSlice";
import { SummaryClub } from "../../functions/src/stravaTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1
    },
    menu: {
      flexGrow: 1
    }
  })
);

export const MenuDrawer: React.FC<{
  open: boolean;
  onBackdropClick: () => void;
}> = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { athlete } = useSelector((state: RootState) => state.app);

  const clubs = athlete?.clubs;
  const currentClubId = athlete?.currentClubId;

  const createClubHandler = (club: SummaryClub) => {
    return () => {
      if (club.id === currentClubId) {
        return;
      }
      dispatch(setCurrentClub(club));
    };
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Drawer
      anchor="right"
      open={props.open}
      onBackdropClick={props.onBackdropClick}
    >
      <Toolbar>
        <Box display="flex" flexGrow={1} alignItems="center">
          <Typography variant="h6" className={classes.title}>
            Choose Club
          </Typography>
          <IconButton onClick={props.onBackdropClick}>
            <MenuOpen />
          </IconButton>
        </Box>
      </Toolbar>
      {clubs && clubs.length ? (
        <List>
          {clubs.map(c => (
            <ListItem
              key={c.id}
              button
              onClick={createClubHandler(c)}
              selected={currentClubId === c.id}
            >
              <ListItemAvatar>
                <Avatar src={c.profile_medium} />
              </ListItemAvatar>
              <ListItemText primary={c.name} secondary={c.sport_type} />
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          <ListItem>
            <ListItemText primary="No clubs!" />
          </ListItem>
        </List>
      )}
      <Divider />
      <List>
        <ListItem
          button
          selected={location.pathname === "/app"}
          onClick={() => {
            history.push("/app");
            props.onBackdropClick();
          }}
        >
          <ListItemIcon>
            <ViewAgendaOutlined />
          </ListItemIcon>
          <ListItemText primary="Feed" />
        </ListItem>
        <ListItem
          button
          selected={location.pathname === "/settings"}
          onClick={() => {
            history.push("/settings");
            props.onBackdropClick();
          }}
        >
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem onClick={handleLogout} button>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default MenuDrawer;
