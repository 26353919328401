import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@material-ui/icons/Menu";
import {
  AppBar,
  Avatar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { RootState } from "../store/rootReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "1rem"
    },
    title: {
      flexGrow: 1
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      margin: theme.spacing(1)
    }
  })
);
export const Bar: React.FC<{
  onAvatarClick: () => void;
}> = props => {
  const classes = useStyles();
  const { user, performingAction } = useSelector(
    (state: RootState) => state.app
  );

  return (
    <AppBar color="primary" position="static" className={classes.root}>
      <Toolbar>
        <Box display="flex" flexGrow={1} alignItems="center">
          <Typography color="inherit" variant="h6" className={classes.title}>
            Sidekick
          </Typography>
          {user && (
            <div>
              <Avatar
                className={classes.avatar}
                src={user.photoURL || undefined}
                alt={user.displayName || undefined}
              ></Avatar>
            </div>
          )}
          {user && (
            <IconButton onClick={props.onAvatarClick}>
              <Menu />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      {performingAction && <LinearProgress />}
    </AppBar>
  );
};

export default Bar;
