import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  RadioGroup,
  Radio,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
  TextField,
  MenuItem
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { Workout ,WorkoutType, allWorkoutTypes} from "../sidekickTypes";
import { RootState } from "../store/rootReducer";
import { createWorkout } from "../store/workoutSlice";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   width: '25%'
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  })
);

export const WorkoutForm: React.FC<{
  open: boolean;
  workout: Workout | null;
  onClose?: (value: Workout | null) => void;
}> = props => {
  const classes = useStyles();
  const todayNoTime = new Date();
  todayNoTime.setSeconds(0);
  todayNoTime.setMinutes(0);
  todayNoTime.setHours(0);
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.app);
  const hasSpecificTime = !props.workout?.timeOfDay
  let initialDate:Date|null = null;
  if (props.workout?.epochDate){
    initialDate = new Date(props.workout.epochDate)
  }
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(initialDate);
  const [selectedType, setSelectedType] = React.useState<WorkoutType>(props.workout?.type || "Ride");
  const [selectedTimeOfDay, setTimeOfDay] = React.useState<"AM" | "PM">(props.workout?.timeOfDay || "AM");
  const [specificTime, setSpecificTime] = React.useState(hasSpecificTime);
  const [description, setDescription] = React.useState<string>(props.workout?.description || "");

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleTimeOfDayChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTimeOfDay(event.target.value as "AM" | "PM");
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedType(event.target.value as WorkoutType);
  };

  const handleSpecificTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSpecificTime(event.target.checked);
  };

  const handleClose = () => {
    props.onClose && props.onClose(props.workout);
  };

  const handleCancel = () => {
    props.onClose && props.onClose(null);
  };

  const handleDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleSave = () => {
    if (specificTime) {
      dispatch(
        createWorkout({
          id: props.workout?.id || "",
          epochDate: selectedDate!.getTime(),
          type: selectedType,
          description,
          owner: user
        })
      );
    } else {
      let t = selectedDate!;
      t.setHours(0);
      t.setMinutes(0);
      t.setSeconds(0);
      dispatch(
        createWorkout({
          id: props.workout?.id || "",
          epochDate: t.getTime(),
          timeOfDay: selectedTimeOfDay,
          type: selectedType,
          description,
          owner: user
        })
      );
    }
    props.onClose && props.onClose(null);
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      color="primary"
      maxWidth={"sm"}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DialogTitle>Create Workout</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={specificTime}
                  onChange={handleSpecificTimeChange}
                  value="specific"
                />
              }
              label="Specific Time"
            />
          </FormControl>

          <FormControl required fullWidth>
            <InputLabel>Type</InputLabel>
            <Select id="type" value={selectedType} onChange={handleTypeChange}>
              {allWorkoutTypes.map(wt => (
                <MenuItem key={wt} value={wt}>
                  {wt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            minDate={todayNoTime}
            // maxDate={addDays(new Date(), 7)}
            minDateMessage="Cannot create workout in the past!"
            margin="normal"
            id="date-picker-inline"
            label="Workout date"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            fullWidth
            autoOk={true}
            disablePast={true}
            required
          />

          {specificTime && (
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Workout time"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
              minutesStep={5}
              fullWidth
              autoOk={true}
            />
          )}

          {!specificTime && (
            <FormControl fullWidth margin="dense">
              <FormLabel component="legend">Time of Day</FormLabel>

              <RadioGroup
                value={selectedTimeOfDay}
                onChange={handleTimeOfDayChange}
                aria-label="time of day"
                row
              >
                <FormControlLabel value="AM" control={<Radio />} label="AM" />
                <FormControlLabel value="PM" control={<Radio />} label="PM" />
              </RadioGroup>
            </FormControl>
          )}

          <TextField
            id="description"
            value={description}
            label={`Description (${description.length}/200)`}
            multiline
            rows="4"
            variant="outlined"
            inputProps={{ maxLength: 200 }}
            fullWidth
            onChange={handleDescription}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
};

export default WorkoutForm;
