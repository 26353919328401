import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "@firebase/auth-types";
import { AppThunk } from "../store";
import { firestore, functions } from "../firebase";

import {
  SummaryClub,
  SummaryAthlete,
  DetailedAthlete
} from "../../functions/src/stravaTypes";

type ClubState = {
  currentClubId?: number;
  error?: string;
  clubs: SummaryClub[];
};

let initialState: ClubState = {
  currentClubId: undefined,
  error: undefined,
  clubs: []
};

const clubSlice = createSlice({
  name: "club",
  initialState,
  reducers: {
    onClubLoadSuccess(state, action: PayloadAction<SummaryClub[]>) {
      state.clubs = action.payload;
    },
    onMemberLoadSuccess(state, action: PayloadAction<SummaryAthlete[]>) {
      console.log(action.payload);
    },
   
    actionFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    }
  }
});

export const {
  onClubLoadSuccess,
  onMemberLoadSuccess,
  actionFailed
} = clubSlice.actions;

export default clubSlice.reducer;

async function saveClubs(clubs: SummaryClub[], member: DetailedAthlete) {
  let batch = firestore.batch();

  let coll = firestore.collection("clubs");
  clubs.forEach(club => {
    let clubRef = coll.doc(`club:${club.id}`);
    batch.set(clubRef, club, { merge: true });
    let membersColl = clubRef.collection("members");
    let memberRef = membersColl.doc(`member:${member.id}`);
    batch.set(memberRef, member, { merge: true });
    // promises.push(
    //   coll
    //     .doc()
    //     .set(club, { merge: true })
    //     .catch(error => {
    //       console.log(error);
    //     })
    // );
  });
  return batch.commit().catch(error => {
    console.log(error);
  });
}

async function saveMembers(clubId: string, members: SummaryAthlete[]) {
  let coll = firestore.collection("clubs");
  let promises: Promise<void>[] = [];
  members.forEach(member => {
    promises.push(
      coll
        .doc(`club:${clubId}`)
        .collection("members")
        .doc(`member:${member.id}`)
        .set(member, { merge: true })
        .catch(error => {
          console.log(error);
        })
    );
  });
  return Promise.all(promises);
}


export async function getClubsFromStrava() {
  const getClubs = functions.httpsCallable("getStravaClubs");
  const clubs = (await getClubs()).data as SummaryClub[];
  return clubs;
}

export const getStravaClubs = (): AppThunk<void> => async (dispatch, getState) => {
  try {
    const clubs = await getClubsFromStrava();
    const athlete = getState().app.athlete;
    await saveClubs(clubs, athlete!);
     dispatch(onClubLoadSuccess(clubs));
  } catch (err) {
     dispatch(actionFailed(err.toString()));
  }
};

export const getStravaClubMembers = (clubId: string): AppThunk<void> => async (
  dispatch,
  getState
) => {
  const getMembers = functions.httpsCallable("getStravaClubMembers");
  try {
    const members = (await getMembers({ clubId })).data as SummaryAthlete[];
    await saveMembers(clubId, members);
    dispatch(onMemberLoadSuccess(members));
  } catch (err) {
    dispatch(actionFailed(err.toString()));
  }
};

