import React from "react";
import { useSelector, useDispatch } from "react-redux";
import format from "date-fns/format";

import clsx from "clsx";
import { Workout } from "../sidekickTypes";
import { joinWorkout, leaveWorkout } from "../store/workoutSlice";
import {
  Card,
  CardHeader,
  CardMedia,
  Avatar,
  CardContent,
  IconButton,
  CardActions,
  Typography,
  Menu,
  MenuItem
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import {
  MoreVert,
  ExposurePlus1Outlined,
  ExposureNeg1Outlined
  // ExpandMoreOutlined
} from "@material-ui/icons";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { RootState } from "../store/rootReducer";
// import WorkoutChat from "./WorkoutChat";
import WorkoutForm from "./WorkoutForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   width: '25%'
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    media: {
      height: 125
    },
    bottomMedia: {
      backgroundPositionY: "bottom"
    },
    avatarGroup: {
      padding: 10,
      marginLeft: 10
    },
    smallAvatar: {
      width: theme.spacing(3),
      height: theme.spacing(3)
    }
  })
);

const getCardSplash = (workout: Workout) => {
  //Photo by asoggetti on Unsplash
  switch (workout.type) {
    case "Run":
      return "/img/running-asoggetti-unsplash.jpg";
    case "Ride":
      return "/img/cycling-victor-xok-unsplash.jpg";
    case "Walk":
      return "/img/walking-jayden-brand-unsplash.jpg";
  }
};

function getTimeDisplay(workout: Workout) {
  let epoch = workout.epochDate;
  let date = new Date(epoch);
  if (workout.timeOfDay) {
    return `${date.toLocaleDateString()} // ${workout.timeOfDay} //`;
  }
  return format(date, "Pp");
}

export const WorkoutCard: React.FC<{
  id: string;
  workout: Workout;
}> = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.app);

  const [expanded, setExpanded] = React.useState(false);
  const [formOpen, setFormOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePlusOneClick = () => {
    dispatch(joinWorkout(props.workout));
  };

  const handleMinusOneClick = () => {
    dispatch(leaveWorkout(props.workout));
  };

  const isOwner = user?.uid === props.workout?.owner?.uid;
  const hasJoined = !!props.workout.joiners?.find(j => j.uid === user?.uid);

  const getCardMediaClasses = () => {
    if (props.workout.type === "Ride" || props.workout.type === "Walk") {
      return classes.bottomMedia;
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleMenuClose();
  };

  const handleEdit = () => {
    handleMenuClose();
    setFormOpen(true);
  };

  const handleFormClose = () => {
    setFormOpen(false);
  };

  return (
    <Card className={classes.root}>
      <CardMedia
        className={clsx(classes.media, getCardMediaClasses())}
        image={getCardSplash(props.workout)}
        title={props.workout.type}
      />
      <CardHeader
        avatar={
          <Avatar src={props.workout.owner?.photoURL || undefined}></Avatar>
        }
        action={
          isOwner && (
            <>
              <IconButton aria-label="settings" onClick={handleMenuClick}>
                <MoreVert />
              </IconButton>
              <Menu
                open={menuOpen}
                keepMounted
                onClose={handleMenuClose}
                anchorEl={anchorEl}
              >
                <MenuItem key="edit" onClick={handleEdit}>
                  Edit
                </MenuItem>
                {/* <MenuItem key="delete" onClick={handleDelete}>
                Delete
              </MenuItem> */}
              </Menu>
            </>
          )
        }
        title={props.workout.type}
        titleTypographyProps={{ variant: "button" }}
        subheader={getTimeDisplay(props.workout)}
      />

      <CardContent>
        <Typography variant="body2">{props.workout.description}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        {!isOwner &&
          (hasJoined ? (
            <IconButton
              onClick={handleMinusOneClick}
              aria-label="leave workout"
            >
              <ExposureNeg1Outlined />
            </IconButton>
          ) : (
            <IconButton onClick={handlePlusOneClick} aria-label="join workout">
              <ExposurePlus1Outlined />
            </IconButton>
          ))}

        {/* <IconButton aria-label="share workout">
          <ShareOutlined />
        </IconButton> */}
        <AvatarGroup className={classes.avatarGroup}>
          {props.workout.joiners &&
            props.workout.joiners.map(joiner => (
              <Avatar
                className={classes.smallAvatar}
                key={joiner.uid}
                src={joiner.photoURL || undefined}
              ></Avatar>
            ))}
        </AvatarGroup>
        {/* <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreOutlined />
        </IconButton> */}
      </CardActions>
      {/* <Collapse in={expanded} unmountOnExit>
        <CardContent>
          <Typography paragraph>Chat</Typography>
          <WorkoutChat workout={props.workout}/>
        </CardContent>
      </Collapse> */}
      <WorkoutForm
        open={formOpen}
        workout={props.workout}
        onClose={handleFormClose}
      />
    </Card>
  );
};

export default WorkoutCard;
